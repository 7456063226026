body.single {
	div#primary {
		article.post {
			margin: 0 0 50px;
			header.post-header {
				h3.post-title {
					font-family: $header-font;
					font-size: 2.25rem;
					margin: 15px 0 10px;
				}
				img.wp-post-image {
					max-width: 100%;
					width: 100%;
					height: auto;
					border-radius: 8px;
				}
				p.metadata {
					line-height: 1.5rem;
					margin: 0 0 10px;
					font-size: .9rem;
					color: rgba( $black, .5 );
				}
			}
			div.post-excerpt {
				p {
					font-size: 1.25rem;
					font-weight: bold;
					line-height: 1.75rem;
					margin: 0 0 15px;
				}
			}
			div.post-content {
				h3 {
					margin: 30px 0 5px;
				}
				p {
					line-height: 1.5rem;
					margin: 0 0 15px;
				}
				blockquote {
					margin: 0 0 15px;
					padding: 5px 0 5px 10px;
					border-left: 5px solid rgba( $black, .5 );
					
					p {
						font-size: 1.25rem;
					}
				}
				ul {
					list-style: outside;
					margin: 0 0 10px 2rem;
					li {
						margin: 0 0 5px;
					}
				}
				pre.wp-block-code {
					margin: 0 0 15px;
					padding: 15px;
					border-radius: 8px;
					background-color: rgba( $black, .1 );
				}
			}
		}
		
		section#explore {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-gap: 15px;
			h4 {
				grid-column: 1 / span 2;
				font-family: $header-font;
				font-size: 1.25rem;
				margin: 0;
				padding: 0;
			}
			article.post {
				margin: 0;
				a {
					display: block;
					
					&:hover {
						color: $black;
						
						h3.post-title {
							transition: all 1s;
							color: $contrast;
						}
					}
					
					h3.post-title {
						font-family: $header-font;
						margin: 15px 0 10px;
					}
					img.wp-post-image {
						max-width: 100%;
						width: 100%;
						height: auto;
						border-radius: 8px;
					}
					p {
						line-height: 1.5rem;
						margin: 0;
						
						&.metadata {
							margin: 0 0 10px;
							font-size: .9rem;
							color: rgba( $black, .5 );
						}
					}
				}
			}
		}
	}
}