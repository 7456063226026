.button {
	display: inline-block;
	margin: 10px 0;
	padding: 10px 14px;
	border-radius: 3px;
	border: 3px solid $black;
	color: $black;
	background: $white;
	text-transform: uppercase;
	transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
	font-weight: bold;
	
	&:hover {
		box-shadow: 0 0 40px 40px $black inset;
		color: $white;
	}
	
	&.block {
		display: block;
		text-align: center;
	}
	&.contrast {
		border: 2px solid $contrast;
		color: $contrast;
		
		&:hover {
			box-shadow: 0 0 40px 40px $contrast inset;
			color: $white;
		}
	}
	&.large {
		padding: 12px 18px;
		font-size: 1.25rem;
	}
}