hr {
	margin: 20px 0;
	padding: 0;
	
	&.light {
		border-color: lighten( $black, 65% );
	}
	&.half {
		width: 50%;
	}
	&.quarter {
		width: 25%;
	}
}