footer#site-footer {
	border-top: 1px solid rgba( $black, .1 );
	padding: 10px 0 0;
	color: rgba( $black, .25 );
	
	@include grid-media( $base ) {
		padding: 30px 0 0;
	}
	
	
	.column {
		p {
			margin: 0;
			padding: 0;
			
			i {
				transition: all 1s;
				color: rgba( $black, .25 );
				&:hover {
					color: $contrast;
				}
			}
		}
		&:last-child {
			margin: 0;
			@include grid-media( $base ) {
				margin: 0;
				text-align: right;
			}
		}
	}
}