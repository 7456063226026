body {
	background: url('../images/bg.jpg') top left no-repeat fixed $white;
	color: $black;
	font-family: $body-font;
	font-weight: 300;
	
	#primary {
		article {
			h2 {
				font-family: $header-font;
				font-size: 1.5rem;
			}
			h3 {
				font-family: $header-font;
				font-size: 1.25rem;
			}
			a {
				font-weight: bold;
			}
		}
	}
	
	p {
		line-height: 1.5rem;
		margin: 0 0 1rem;
	}
	
	a {
		color: $black;
		text-decoration: none;
		transition: all 1s;
		
		&:hover {
			color: $contrast;
		}
	}
}