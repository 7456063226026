div#sidebar {

	div.posts {
		h3.section-header {
			display: block;
			width: 100%;
			text-transform: uppercase;
			margin: 30px 0 10px;
			font-family: $header-font;
			@include grid-media( $base ) {
				display: none;
			}
		}
		article.post {
			a {
				display: block;
				padding: 0 0 30px;
				
				@include grid-media( $base ) {
					padding: 30px;
				}
				
				&:hover {
					color: $black;
					background-color: lighten( $black, 75% );
					
					h3.post-title {
						transition: all 1s;
						color: $contrast;
					}
				}
				
				h3.post-title {
					font-family: $header-font;
					font-size: 1.25rem;
					margin: 15px 0 0;
				}
				img.wp-post-image {
					max-width: 100%;
					width: 100%;
					height: auto;
					border-radius: 8px;
				}
				p {
					line-height: 1.5rem;
					margin: 0;
					
					&.metadata {
						margin: 0;
						font-size: .9rem;
						color: rgba( $black, .5 );
					}
				}
			}
		}
	}
	
	div.projects {
		display: none;
		@include grid-media( $base ) {
			display: block;
			padding: 0px 30px;
		}
		
		h3.section-header {
			display: block;
			width: 100%;
			text-transform: uppercase;
			margin: 30px 0 10px;
			font-family: $header-font;
			@include grid-media( $base ) {
				display: none;
			}
		}
		
		article.project {
			margin: 0 0 30px;
			border-radius: 8px;
			@include aspect-ratio( 16, 9 );
			
			&.vignette {
				box-shadow: inset 1px 1px 50px 5px rgba(1,1,1,.2);
			}
			
			a.content {
				display: block;
				color: $white;
				
				h2 {
					font-size: 1.75rem;
					display: inline;
					font-family: $header-font;
					text-align: center;
					text-transform: uppercase;
					
					@include grid-media( $base ) {
						font-size: 2.5rem;
					}
					
					img.logo {
						@include centerer;
						max-width: 70%;
						max-height: 70%;
						width: auto;
						height: auto;
					}
					span {
						@include centerer;
					}
				}
				
				&:hover {
					-webkit-transform:scale(1.25); /* Safari and Chrome */
				    -moz-transform:scale(1.25); /* Firefox */
				    -ms-transform:scale(1.25); /* IE 9 */
				    -o-transform:scale(1.25); /* Opera */
				     transform:scale(1.25);
				}
			}
		}
	}
	
	hr.spacer {
		margin: 0;
		padding: 0;
		border: none;
		border-top: 1px solid rgba( $black, .1 );
	}
}