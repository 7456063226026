body.home {
	div#content {
		div#primary {
			
			section {
				margin: 0 0 30px;
				@include grid-media( $base ) {
					margin: 0 0 60px;
				}
				
				h2.section-header {
					display: block;
					width: 100%;
					//text-transform: uppercase;
					margin: 0 0 10px;
					font-family: $header-font;
					font-size: 2.75rem;
				}
				h3.section-header {
					display: block;
					width: 100%;
					//text-transform: uppercase;
					margin: 0 0 10px;
					font-family: $header-font;
					font-size: 2rem;
				}
				
				&#welcome {
					@include grid-collapse;
					@include grid-container;
					
					div.section-content {
						p {
							@include grid-column( 4, $mobile );
					
							@include grid-media( $base ) {
								@include grid-column( 4, $base );
								@include grid-push( 4, $base );	
							}
							
							text-align: justify;
						}
						
						blockquote {
							p {
								@include grid-column( 4, $mobile );
								
								font-family: $header-font;
								text-align: center;
								font-size: 2.25rem;
								line-height: 2.25rem;	
													
								@include grid-media( $base ) {
									@include grid-column( 8, $base );
									@include grid-push( 2, $base );	
									
									font-family: $header-font;
									text-align: center;
									font-size: 4rem;
									line-height: 4rem;									
								}
							}
						}
					}
				}
				
				&#featured_projects {
					div.section-content {
						display: grid;
						grid-template-columns: 1fr;
						grid-gap: 15px;
						
						@include grid-media( $base ) {
							grid-template-columns: 1fr 1fr;
						}
					
						article.project {
							margin: 0;
							border-radius: 8px;
							@include aspect-ratio( 16, 9 );
							
							&.vignette {
								box-shadow: inset 1px 1px 50px 5px rgba(1,1,1,.2);
							}
							
							a.content {
								display: block;
								
								h2 {
									display: inline;
							
									img.logo {
										@include centerer;
										max-width: 70%;
										max-height: 70%;
										width: auto;
										height: auto;
									}
								}
								
								&:hover {
									-webkit-transform:scale(1.1); /* Safari and Chrome */
								    -moz-transform:scale(1.1); /* Firefox */
								    -ms-transform:scale(1.1); /* IE 9 */
								    -o-transform:scale(1.1); /* Opera */
								     transform:scale(1.1);
								}
							}
						}
					}
				}
				
				&#secondary_projects {
					div.section-content {
						display: grid;
						grid-template-columns: 1fr 1fr;
						grid-gap: 15px;
						
						@include grid-media( $base ) {
							grid-template-columns: 1fr 1fr 1fr 1fr;
						}
						
						article.project {
							@include aspect-ratio( 16, 9 );
							border-radius: 8px;
							
							&.vignette {
								box-shadow: inset 1px 1px 50px 5px rgba(1,1,1,.2);
							}
							
							a.content {
								display: block;
								color: $white;
								
								h2 {
									font-size: 1rem;
									display: inline;
									font-family: $header-font;
									text-align: center;
									text-transform: uppercase;
									
									@include grid-media( $base ) {
										font-size: 1.75rem;
									}
																
									img.logo {
										@include centerer;
										max-width: 70%;
										max-height: 70%;
										width: auto;
										height: auto;
									}
									span {
										@include centerer;
									}
								}
								
								&:hover {
									-webkit-transform:scale(1.25); /* Safari and Chrome */
								    -moz-transform:scale(1.25); /* Firefox */
								    -ms-transform:scale(1.25); /* IE 9 */
								    -o-transform:scale(1.25); /* Opera */
								     transform:scale(1.25);
								}
							}
						}
					}
				}
				
				&#tertiary {
					display: grid;
					grid-template-columns: 1fr;
					grid-gap: 15px;
						
					@include grid-media( $base ) {
						grid-template-columns: 1fr 1fr;
					}
					
					section {
						div.section-content {
							h4 {
								font-family: $header-font;
								font-size: 1.25rem;
							}
							p {
								a {
									//font-family: $meta-data;
									//font-weight: bold;
									text-transform: uppercase;
								}
							}
							ul.client-list {
								list-style: none;
								margin: 0;
								padding: 0;
								
								li.client {
									font-family: $meta-data;
									text-transform: uppercase;
									display: inline-block;
									font-size: 1.25rem;
									line-height: 1.75rem;
									margin: 0 1.25rem 1rem 0;
								}
							}
						}
					}
				}
			}
		}
	}
}