@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@mixin centerer($horizontal: true, $vertical: true) {
	position: absolute;
	@if ($horizontal and $vertical) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if ($horizontal) {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if ($vertical) {
		top: 50%;
		transform: translate(0, -50%);
	}
}