@import url('https://fonts.googleapis.com/css?family=Bigshot+One|Dosis:400;700|PT-Sans:400,400i,700,700i');

@import "../vendors/HTML5-Reset/assets/css/reset.css";
@import "../vendors/neat/core/neat";

@import "../vendors/Font-Awesome-Pro/scss/fontawesome";
@import "../vendors/Font-Awesome-Pro/scss/regular";
@import "../vendors/Font-Awesome-Pro/scss/brands";

@import "variables";

@import "modules/grid";
@import "modules/layout";
@import "modules/navigation";
@import "modules/widgets";
@import "modules/buttons";
@import "modules/woocommerce";

@import "partials/mixins";
@import "partials/dividers";
@import "partials/design";

@import "templates/header";
@import "templates/front-page";
@import "templates/page";
@import "templates/single";
@import "templates/single-projects";
@import "templates/sidebar";
@import "templates/footer";
