nav.menu {
	text-align: right;
	ul {
		li {
			display: block;
			text-transform: uppercase;
			font-weight: 700;
			
			a {
				
			}
		}
	}
}