div#sidebar {
	ul#widgets,
  ul#shop-widgets {
		li.widget {
			padding: 30px 0 30px;

			@include grid-media( $base ) {
				padding: 30px;
				border-bottom: 1px solid rgba( $black, .1 );
			}

			h2.widgettitle {
				font-family: $header-font;
				font-size: 1.25rem;
				margin: 0;
			}

			p {
				margin: 0 0 20px;
				&:last-child {
					margin: 0;
				}
			}

			&.widget_search {
				label {
					span {
						display: none;
					}
				}
				input.search-submit {
					display: none;
				}
				input.search-field {
					display: block;
					width: 100%;
				}
			}
		}
	}
}
