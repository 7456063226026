header#site-header {
	text-align: center;
	@include grid-media( $base ) {
		text-align: left;
	}
	h1 {
		margin: 30px 0;
		a {
			img.logo {
				max-width: 100%;
				width: 150px;
				height: auto;
			}
		}
	}
	nav#primary-nav {
		ul.menu {
			list-style: none;
			margin: 30px 30px 30px 0;
			padding: 0;
			text-align: right;
			
			li {
				display: inline;
				margin: 0 0 0 1rem;
				
				a {
					text-transform: uppercase;
					font-weight: bold;
					font-size: .9rem;
				}
				
				&.current-menu-item {
					a {
						border-bottom: 3px solid $contrast;
					}
				}
			}
		}
	}
}