body.woocommerce {
  h1.page-title {
    font-family: $header-font;
    font-size: 2.25rem;
    margin: 15px 0 10px;
  }

  .woocommerce-ordering {
    display: none;
  }

  ul.products {
    li.product {
      position: relative;
      a {
        &:hover {
          span.onsale {
            color: $white;
          }
        }
      }

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      h2 {
        font-weight: bold;
      }

      a.button {
        display: block;
        text-align: center;
      }
    }
  }

  ul.page-numbers {
    margin: 20px 0 0;
    text-align: center;

    li {
      display: inline;

      span.current {
        font-weight: bold;
        background-color: $black;
        color: $white;
        padding: 5px 8px;
        border-radius: 3px;
      }

      a {
        padding: 3px 6px;
        border: 2px solid $black;
        border-radius: 3px;

        &:hover {
          background-color: $black;
          color: $white;
        }
      }
    }
  }

  &.single-product {
    div.product {
      position: relative;

      .woocommerce-product-gallery {
        img {
          width: 100%;
          max-width: 100%;
          height: 100%;
        }
      }

      .summary {
        table.variations {
          margin: 0 0 20px;
          tr {
            td {
              padding: 0 10px 10px 0;
            }
          }
        }
        a.reset_variations {
          margin: 10px 0 0;
          display: block;
        }

        .product_meta {
          span {
            display: block;
            font-weight: bold;

            a,
            span {
              display: inline;
              font-weight: normal;
            }
          }
        }
      }

      h1.product_title {
        font-family: $header-font;
        font-size: 2.25rem;
        margin: 15px 0 10px;
      }
    }
    section.related.products {
      h2 {
        font-family: $header-font;
        font-size: 2rem;
        margin: 15px 0 10px;
      }
    }
  }
}
