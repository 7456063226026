body {
	padding: 0 0 100px 0;
	
	&.home {
		div#wrapper {
			div#content {
				@include grid-container;
				
				div#primary {
					@include grid-column( 4, $mobile );
					
					@include grid-media( $base ) {
						@include grid-column( 10, $base );
						@include grid-push( 1, $base );	
					}
					border: none;
					padding: 0 10px 0 0;
				}
				div#sidebar {
					display: none;
				}
			}
		}
	}
	
	div#wrapper {
		header#site-header {
			@include grid-container;
			
			h1 {
				@include grid-column( 4, $mobile );
			
				@include grid-media( $base ) {
					@include grid-column( 2, $base );
					@include grid-push( 1, $base );
				}
			}
			nav#primary-nav {
				//@include grid-column( 4, $mobile );
				display: none;
				
				@include grid-media( $base ) {
					display: block;
					@include grid-column( 8, $base );
				}
			}
		}
		div#content {
			@include grid-container;
			
			div#primary {
				@include grid-column( 4, $mobile );
				
				@include grid-media( $base ) {
					@include grid-column( 6, $base );
					@include grid-push( 1, $base );
					
					border-right: 1px solid rgba( $black, .1 );
					padding: 0 30px 30px 0;
				}
			}
			div#sidebar {
				@include grid-column( 4, $mobile );
				
				@include grid-media( $base ) {
					@include grid-column( 4, $base );
				}
			}
		}
		footer#site-footer {
			@include grid-container;
			
			.column {
				@include grid-column( 4, $mobile );
				
				@include grid-media( $base ) {
					@include grid-column( 5, $base );
					@include grid-push( 1, $base );
				}
				
				&:last-child {
					@include grid-media( $base ) {
						@include grid-column( 5, $base );
						@include grid-push( 0, $base );
					}
				}
			}
		}
	}
}