body.single-projects {
	div#primary {
		article.projects {
			margin: 0 0 50px 0;
			
			@include grid-media( $base ) {
				margin: 0 -15px 50px 0;
			}
			
			header.project-header {
				@include aspect-ratio( 16, 9 );
				border-radius: 8px;
				margin: 0 0 15px;
				
				&.vignette {
					box-shadow: inset 1px 1px 50px 5px rgba(1,1,1,.2);
				}
					
				h2.project-title {
					font-size: 1.75rem;
					display: inline;
					font-family: $header-font;
					text-align: center;
					text-transform: uppercase;
					
					@include grid-media( $base ) {
						font-size: 4rem;					
					}
					
					img.logo {
						@include centerer;
						max-width: 70%;
						max-height: 70%;
						width: auto;
						height: auto;
					}
					span {
						@include centerer;
					}
				}
			}
			div.post-excerpt {
				p {
					font-size: 1.25rem;
					font-weight: bold;
					line-height: 1.75rem;
					margin: 0 0 15px;
				}
			}
			div.post-content {
				h2, h3 {
					margin: 30px 0 5px;
				}
				p {
					line-height: 1.5rem;
					margin: 0 0 15px;
				}
				blockquote {
					margin: 0 0 15px;
					padding: 5px 0 5px 10px;
					border-left: 5px solid rgba( $black, .5 );
					
					p {
						font-size: 1.25rem;
					}
				}
				ul {
					list-style: outside;
					margin: 0 0 10px 2rem;
					li {
						margin: 0 0 5px;
					}
				}
				pre.wp-block-code {
					margin: 0 0 15px;
					padding: 15px;
					border-radius: 8px;
					background-color: rgba( $black, .1 );
				}
				div.browser {
					position: relative;
					background: url('../images/laptop.svg') top center no-repeat;
					background-size: 100% 100%;
					margin: 30px 0 20px;
					padding: 20px 90px 55px;
					
					figure {
						margin: 0;
						height: 325px;
						overflow-y: scroll;
						
						img {
							width: 100%;
							height: auto;
						}
					}
				}
			}
			div.metadata {
				background-color: lighten( $black, 70% );
				padding: 15px;
				display: block;
				font-family: $meta-data;
				text-transform: uppercase;
				
				@include grid-media( $base ) {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-gap: 15px;
				}
				
				div.item {
					margin: 0;
					&:last-child {
						margin: 0;
					}
					@include grid-media( $base ) {
						margin: 0;
					}
					
					h5 {
						font-size: 1rem;
						font-weight: bold;
						letter-spacing: 1px;
						color: lighten( $black, 40% );
					}
					p {
						margin: 0;
						a {
							font-weight: normal;
						}
					}
					ul.keywords {
						li {
							display: inline-block;
							margin: 0 .5rem 0 0;
						}
						li:before {
							content: "#";
						}
					}
				}
			}
		}
				
		section#explore {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-gap: 15px;
			
			h4 {
				grid-column: 1 / span 2;
				font-family: $header-font;
				font-size: 1.25rem;
				margin: 0;
				padding: 0;
			}
			article.project {
				margin: 0;
				border-radius: 8px;
				@include aspect-ratio( 16, 9 );
				
				&.vignette {
					box-shadow: inset 1px 1px 50px 5px rgba(1,1,1,.2);
				}
				
				a.content {
					display: block;
					color: $white;
					
					h2 {
						font-size: 1.75rem;
						display: inline;
						font-family: $header-font;
						text-align: center;
						text-transform: uppercase;
						
						img.logo {
							@include centerer;
							max-width: 70%;
							max-height: 70%;
							width: auto;
							height: auto;
						}
						span {
							@include centerer;
						}
					}
					
					&:hover {
						-webkit-transform:scale(1.25); /* Safari and Chrome */
					    -moz-transform:scale(1.25); /* Firefox */
					    -ms-transform:scale(1.25); /* IE 9 */
					    -o-transform:scale(1.25); /* Opera */
					     transform:scale(1.25);
					}
				}
			}
		}
	}
}